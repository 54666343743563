.fizjoDetailContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

.fizjoHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.fizjoImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fizjoInfo {
    flex: 1;
}

.fizjoName {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.fizjoSpecialization {
    margin: 5px 0;
    font-size: 18px;
    color: #555;
}

.fizjoBody {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.fizjoDescription {
    margin: 20px 0;
    font-size: 16px;
    line-height: 1.5;
    color: #666;
}

.fizjoAddress {
    margin-top: 20px;
}

.fizjoAddress h2 {
    margin: 0 0 10px;
    font-size: 20px;
    color: #333;
}

.fizjoAddress p {
    margin: 5px 0;
    font-size: 16px;
    color: #666;
}

.loading {
    text-align: center;
    font-size: 20px;
    color: #666;
    margin-top: 50px;
}
