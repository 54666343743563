* {
  font-family: 'Be Vietnam Pro', sans-serif;
}

.item {
  margin: 1rem 0;
}

.cardBlock {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
}

.image {
  /* width: 20%; */
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  border-radius: 6px;
  flex: 1;
}

.image img {
  /* width: 80%; */
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.content {
  /* text-align: left; */
  padding: 1rem;
  flex: 1;

  
}

.content h3 {
  vertical-align: top;
  font-size: 1.5rem;
  color: #2c292b;
}

.actions {
  padding: 1.5rem;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.actions button {
  font: inherit;
  cursor: pointer;
  color: #77002e;
  border: 1px solid #77002e;
  background-color: transparent;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  flex: 1;
  align-self: flex-end;
}

.actions button:hover,
.actions button:active {
  background-color: #ffe2ed;
}

